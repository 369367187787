import { mapGetters } from 'vuex';
import filenameMixin from './filenameMixin';

export default {
  mixins: [filenameMixin],
  computed: {
    ...mapGetters({
      dupes: 'duplicates/getDupes',
      references: 'references/getRefs'
    }),
    duplicates() {
      if (this.dupes) {
        return this.dupes.flat();
      } else {
        return [];
      }
    },
    dedupedRefs() {
      if (this.duplicates) {
        return this.duplicates.reduce((filtered, dupeList) => {
          // Deduped that dont have screened in group
          if (
            dupeList.length > 1 &&
            !dupeList.some((dupe) => dupe.isScreened)
          ) {
            // Find which record we want to keep
            // Clone dupe list
            let clonedDupeList = [];
            dupeList.forEach((val) =>
              clonedDupeList.push(Object.assign({}, val))
            );
            // Merge dupes (first gets priority)
            clonedDupeList = clonedDupeList.reverse();
            let mergedDupe = clonedDupeList.reduce(function (result, current) {
              return Object.assign(result, current);
            }, {});
            // Assign dupe to keep last to ensure that the correct record is kept
            let dupeKeep = dupeList.find((val) => val.keep);
            if (dupeKeep) {
              Object.assign(mergedDupe, dupeKeep);
            }
            filtered.push(mergedDupe);
          }
          // Nondupes that arent screened
          else if (
            dupeList.length == 1 &&
            !dupeList.some((dupe) => dupe.isScreened)
          ) {
            filtered.push(dupeList[0]);
          }
          return filtered;
        }, []);
      } else {
        return [];
      }
    }
  },
  methods: {
    allRefs() {
      if (this.duplicates) {
        return this.duplicates.flat();
      } else {
        return [];
      }
    },
    removedDuplicates() {
      if (this.duplicates) {
        return this.duplicates
          .reduce((filtered, dupeList) => {
            // Filter out any screened groups and non-dupes
            if (
              Array.isArray(dupeList) &&
              !dupeList.some((dupe) => dupe.isScreened) &&
              dupeList.length > 1
            ) {
              // Push everything but the first ref (original)
              filtered.push(dupeList.slice(1));
            }
            return filtered;
          }, [])
          .flat();
      } else {
        return [];
      }
    },
    removedScreened() {
      if (this.duplicates) {
        // Filter groups that have 1 reference already screened
        return this.duplicates
          .filter(
            (dupeList) =>
              Array.isArray(dupeList) &&
              dupeList.some((dupe) => dupe.isScreened)
          )
          .flat();
      }
    },
    removedAll() {
      if (this.duplicates) {
        // Filter groups that have 1 reference already screened
        return this.duplicates
          .reduce((filtered, dupeList) => {
            // If is screened
            if (
              Array.isArray(dupeList) &&
              dupeList.some((dupe) => dupe.isScreened)
            ) {
              filtered.push(dupeList);
            }
            // Else is regular dupe
            else if (dupeList.length > 1) {
              filtered.push(dupeList.slice(1));
            }
            return filtered;
          }, [])
          .flat();
      }
    },
    callDownload(refs, hint) {
      return this.$tera.setProjectLibrary(
        /*refs.map(ref => {
					return this.references[ref.id];
				}),
				refs, {
					hint,
				},*/
        {
          refs: refs.map((ref) => {
            return this.references[ref.id];
          }),
          hint: hint
        }
      );
    }
  }
};
