<template>
	<div class="mt-5">
		<b-card class="shadow" no-body>
			<b-tabs v-model="tabIndex" pills card no-fade class="upload-tab-content">
				<!-- Deduplicate -->
				<b-tab title="Deduplicate">
					<div class="m-3">
						<b>To begin deduplication, choose a library or drop it here</b>
						<b-input-group v-for="(deduplicateFile, i) of deduplicateFiles" :key="i" class="mb-3">
							<tera-file-select title="Select a citation library to deduplicate"
								:filters="{ library: true }" placeholder="Select a citation library to deduplicate..."
								@change="$set(deduplicateFiles, i, $event)" class="bg-white" />
							<b-button v-if="i > 0" class="ml-2" variant="danger" @click="deduplicateFiles.splice(i, 1)">
								Remove
							</b-button>
						</b-input-group>
						<b-button @click="deduplicateFiles.push(null)">Add Another File <b-icon-plus /></b-button>
						<b-button
							v-if="deduplicateFiles.some(file => !!file)"
							variant="success"
							class="ml-3"
							@click="fileUpload"
						>
						Deduplicate
						</b-button>
					</div>
				</b-tab>
				<!-- Remove Already Screened -->
				<b-tab title="Identify New References">
					<div class="m-3">
						<b>Already Screened Articles:</b>
						<tera-file-select title="Select a citation library for already screened references"
							:filters="{ library: true }"
							placeholder="Select a citation library for already screened references"
							@change="screenedFile = $event" class="bg-white" />
					</div>
					<div class="m-3">
						<b>New Articles to Deduplicate:</b>
						<b-input-group v-for="(deduplicateFile, i) of deduplicateFiles" :key="i" class="mb-3">
							<tera-file-select title="Select a citation library of possibly duplicated references"
								:filters="{ library: true }"
								placeholder="Select a citation library of possibly duplicated references"
								@change="$set(deduplicateFiles, i, $event)" class="bg-white" />
							<template v-if="i > 0" #append>
								<b-button class="ml-2" variant="danger"
									@click="deduplicateFiles.splice(i, 1)">Remove</b-button>
							</template>
						</b-input-group>
						<b-button @click="deduplicateFiles.push(null)">Add Another File <b-icon-plus /></b-button>
						<b-button v-if="screenedFile && deduplicateFiles.some(file => !!file)" variant="success"
							class="ml-3" @click="fileUpload">Identify</b-button>
					</div>
				</b-tab>
				<!-- Algorithm Control -->
				<div class="ml-5 mr-5 mb-5">
					<b-form-group content-cols="4">

						<!-- <template v-slot:description>
							<div id="tooltip-algorithm">Deduplicator Algorithm <b-icon-question-circle-fill /></div>
						</template> -->
						<!-- <b-tooltip style="width:500px; max-width: none" custom-class="algorithm-info" target="tooltip-algorithm" triggers="hover" placement="bottom">
							<h6>The algorithm used by Deduplicator</h6>
							<b>Focused:</b><br/>
							This algorithm is designed for people who want to identify as many duplicates as possible and have time to manually check the results. It may mislabel non-duplicates but misses very few actual duplicates, this means the results must be manually checked.<br/>
							Ideal if you:<br/>
							<ul>
								<li>Don't want to miss any duplicates</li>
								<li>Don't mind investing time to check the results</li>
								<li>e.g. those with small (&lt;2000) results sets</li>
							</ul>
							<b>Relaxed:</b><br/>
							This algorithm is designed for people who want to spend minimal time manually checking the results. It does not mislabel non-duplicates at the expense of missing a small number of duplicates. This means the results don't have to be manually checked.<br/>
							Ideal if you:
							<ul>
								<li>Don't want to check the results</li>
								<li>Don't mind a small number of duplicates missed</li>
								<li>e.g. those with large (&gt;2000) results sets</li>
							</ul> -->
						<!-- <b>Balanced:</b>
							Ideal for medium libraries, balances between duplicate detection and the required checking time<br/><br/> -->
						<!-- </b-tooltip> -->
						<b-form-select :value="strategy" @input="$store.commit('metadata/setStrategy', $event)"
							:options="algorithmOptions"></b-form-select>
						<b-button class="btn-popup" v-b-modal="'deduplicator-modal'">Deduplicator Algorithm
							<b-icon-question-circle-fill />
						</b-button>
						<!-- The deduplicator-modal -->
						<b-modal title="Deduplicator Algorithm" style="width:500px; max-width: none;"
							id="deduplicator-modal">
							<h6>The algorithm used by Deduplicator</h6>
							<b>Focused:</b><br />
							This algorithm is designed for people who want to identify as many duplicates as possible and
							have time to manually check the results. It may mislabel non-duplicates but misses very few
							actual duplicates, this means the results must be manually checked.<br />
							Ideal if you:<br />
							<ul>
								<li>Don't want to miss any duplicates</li>
								<li>Don't mind investing time to check the results</li>
								<li>e.g. those with small (&lt;2000) results sets</li>
							</ul>
							<b>Relaxed:</b><br />
							This algorithm is designed for people who want to spend minimal time manually checking the
							results. It does not mislabel non-duplicates at the expense of missing a small number of
							duplicates. This means the results don't have to be manually checked.<br />
							Ideal if you:
							<ul>
								<li>Don't want to check the results</li>
								<li>Don't mind a small number of duplicates missed</li>
								<li>e.g. those with large (&gt;2000) results sets</li>
							</ul>
							<template #modal-footer>
								<button v-b-modal.modal-close_visit @click="hideModal"
									class="btn btn-modalclose">Ok</button>
							</template>
						</b-modal>
					</b-form-group>
				</div>
			</b-tabs>
		</b-card>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import importMixin from "../mixins/importMixin"
import loadMixin from "../mixins/loadMixin"
import TeraFileSelect from '@iebh/tera-fy/widgets/tera-file-select.vue';

export default {
	name: 'FileUpload',
	components: {
		TeraFileSelect,
	},
	mixins: [importMixin, loadMixin],
	data() {
		return {
			screenedFile: null,
			deduplicateFiles: [null],
			tabIndex: 0,
			algorithmOptions: [
				{ value: 'forbesMinFN', text: 'Focused' },
				{ value: 'forbesMinFP', text: 'Relaxed' },
				// { value: 'forbes', text: 'Balanced' },
			]
		}
	},
	computed: {
		...mapGetters({
			strategy: 'metadata/getStrategy'
		})
	},
	methods: {
		fileUpload() {
			if (this.tabIndex === 1 && this.screenedFile && this.deduplicateFiles.some(file => !!file)) {
				this.importFiles(this.deduplicateFiles, this.screenedFile);
			} else if (this.deduplicateFiles.some(file => !!file)) {
				// Read file extention
				const fileExtentions = this.deduplicateFiles.map(file => this.getFileExtention(file.name));
				// If there is a .ddpe file, load that otherwise import all the files
				const ddpeIndex = fileExtentions.findIndex(extention => extention === ".ddpe");
				if (ddpeIndex >= 0)
					this.loadFile(this.deduplicateFiles[ddpeIndex]);
				else
					this.importFiles(this.deduplicateFiles.filter(file => !!file));
			}
		},
		hideModal() {
			this.$root.$emit('bv::hide::modal', 'deduplicator-modal', '#btnShow')
		}
	}
}
</script>

<style>
.upload-tab-content {
	background-color: #eeeeee;
}

.algorithm-info {
	max-width: none;
	width: 500px;
}

.algorithm-info>.tooltip-inner {
	/* Removes the default max-width */
	max-width: none;
	/* Other styles */
	text-align: start;
	padding: 10px;
}

.btn-popup {
	background: transparent !important;
	color: #6c757d !important;
	border: transparent !important;
	font-size: 80% !important;
	font-weight: 400 !important;
	padding: initial !important;
}

.btn-modalclose {
	background-color: #568196 !important;
	color: white !important;
}

.modal-body {
	height: 300px;
	overflow: auto;
}

/* width */
::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px rgb(189, 201, 247);
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #568196;
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #568181;
}

.nav-pills .nav-link.active {
	color: blue !important;
	background-color: transparent !important;
	border-bottom: solid 2px blue;
	border-radius: 0px;
	/* box-shadow: 0px 1px 3px 0px grey; */
}

.nav-pills .nav-link {
	color: darkgray;
}

.nav-link:hover {
	color: black;
}
</style>
